.AwesomeReadme {
  position: relative;
  max-width: 768px;
}

a.ViewOnGithubBtn {
  border-bottom: 0px;
}

.ReadmeInfo {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: -11px;
  z-index: 10;
  padding: 10px;
  opacity: 0.9;
}

.TOCButton {
  cursor: pointer;
  color: #ff2e88;
}

.ReadmeCategory {
  position: absolute;
  top: 40px;
  left: 0;
  max-height: 400px;
  max-width: 300px;
  overflow: scroll;
  background-color: white;
  border: 1px solid lightgray;
  z-index: 50;
  padding: 15px;
}

.ReadmeCategoryCloseButton {
  cursor: pointer;
  font-size: 1.5rem;
  position: sticky;
  top: 0;
  color: grey;
}

.ReadmeCategoryCloseButton :hover {
  color: #ff2e88;
}

.ReadmeCategory > div {
  cursor: pointer;
  color: #ff2e88;
}

.ReadmeCategory > div:hover {
  text-decoration: underline;
}

.scrollToTop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  font-size: 1.2rem;
}

.scrollToTop:hover {
  color: red;
}

@media (min-width: 768px) {
  .AwesomeReadme {
    padding-left: 20px;
  }
}

@media (max-width: 768px) {
  .ReadmeInfo {
    flex-direction: column;
  }
}
