.AwesomeSearch {
  max-width: 1024px;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  height: 100vh;
  overflow-y: auto;
}

.dark-theme {
  background-color: #151513;
}

.normal-theme {
  border: 1px solid #eee;
}

.BurgerButton {
  font-size: 20px;
  display: block;
  position: fixed;
  right: 10px;
  top: 10px;
  cursor: pointer;
  margin: 5px;
  z-index: 999;
}

@media (max-width: 768px) {
  .AwesomeSearch {
    width: 100%;
  }

  .TocButton {
    display: none;
  }

  .StarButton {
    display: none;
  }
}

@media (min-width: 768px) {
  .BurgerButton {
    display: none;
  }
}
